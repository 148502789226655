<script>
export default {
    props: {
        menu: {
            type: Boolean,
            default() {
                return true
            }
        },
        bg: {
            type: String,
            default() {
                return 'bg-pttr-white'
            }
        },
    }
}
</script>
<template>
    <footer class="footer" bg="">
        <img src="/images/footerv2.png" alt="">
    </footer>
</template>
<style lang="scss" scoped>
.footer{
    background: #fff;
    padding-top: 0rem;
    border-top: 6px solid #ffd100;
    img{
        width: 95%;
        display: block;
        margin: 0 auto;
    }
}
</style>
