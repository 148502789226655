import Vue from 'vue'

export const setAvatarSelected = (state, payload) => {
    Vue.set(state, 'avatar_selected', payload)
}
export const setStatus = (state, payload) => {
    Vue.set(state, 'status', payload)
}

export const setUsers = (state, payload) => {
    Vue.set(state, 'users', payload)
}


export const addInTableCards = (state, payload) => {
    let card = state.cards.find(c => c.id == payload.id)
    state.inTableCards.push(card)
    if(card.color) {
        setCurrentColor(state, card.color)
        // let color = state.colors.find(c => c.id == card.color)
        // Vue.set(state, 'currentColor',color )
        // addMessage(state, {message: color.label, type: 'color', id: Date.now()})
    }
    
    
}

export const addMessage = (state, payload) => {
    let nexList = state.listMessages.filter(message => {
        return message.type != payload.type
    })


    nexList.push(payload)

    Vue.set(state, 'listMessages', nexList)
}
export const removeFirstMessage = (state) => {
    state.listMessages.shift()
}

export const setMyHand = (state, payload) => {
    let cards = []

    payload.forEach(p => {
        let card = state.cards.find(c => c.id == p)
        cards.unshift(card)
    })
    Vue.set(state, 'myHand', cards)
}

export const setDeckCards = (state, payload) => {
    let cards = []
    payload.forEach(c => {
        let card = state.cards.find(c_ => c_.id == c)
        cards.push({...card})
    });
    Vue.set(state, 'deckCards', cards)
}
export const setCurrentUserId = (state, payload) => {
    Vue.set(state, 'currentUserId', payload)
}
export const resetCurrentTimer = (state) => {
    state.currentTimer = state.timeByUser
}
export const decreaseCurrenTimer = (state) => {
    state.currentTimer = state.currentTimer - 1
}
export const setDropingCard = (state, payload) => {
    state.dropingCard = payload
}
export const setTakingCard = (state, payload) => {
    state.takingCard = payload
}
export const setWinner = (state, payload) => {
    Vue.set(state, 'winner', payload)
}
export const startSocket = (state, io) => {
    // state.socket =   io('ws://localhost:3000/uno-game', {
    //     transports: ['websocket']
    // })
    state.socket =   io('wss://caravana-ws.saa-redunica.com/uno-game', {
        transports: ['websocket']
    })

    // state.socket =  io('ws://localhost:3000/uno-game')
}

export const setChangeColor = (state, payload)  => {
    state.changeColor.card = payload.card
    state.changeColor.show = payload.show
}

export const setColor = (state, color) => {
    state.changeColor.color = color
    state.changeColor.show = false
}

export const setCurrentColor = (state, id) => {
   
    if(!state.currentColor || state.currentColor.id != id) {
        let color = state.colors.find(c => c.id == id)
    
        Vue.set(state, 'currentColor',color )
    
       
        addMessage(state, {message: color.label, type: 'color', id: Date.now()})
    }
}

export const setGameTimer = (state, interval) => {
    state.gameTimer = interval
}

export const stopGameTimer = (state) => {
    if(state.gameTimer) {
        clearInterval(state.gameTimer)
    }
}

export const setSayOne = (state, payload) => {
    for (const key in payload) {
        state.sayOne[key] = payload[key]
    }
    if(state.sayOne.trigger) {
        clearTimeout(state.sayOne.timer)
    }
}

export const addAudio = (state,  payload) => {

    state.audios.preload.push(payload)
}
export const addImage = (state,  payload) => {

    state.images.preload.push(payload)
}

export const startLoadResources = (state) => {
    state.loading = true
    state.failResources = false
    state.audios.preload.forEach(item => {
        var audio = new Audio();
        audio.oncanplaythrough = () => {
            let vuex = state
            console.log(vuex)
            vuex.audios.loaded_resources[item] = audio
            checkLoaded()
        };
        audio.onerror = () => {
            let vuex = state
            vuex.audios.fail.push(audio)
            vuex.loading = false
            vuex.failResources = true
        };
        audio.src = item;
        
    })
    state.images.preload.forEach(item => {
        var image = new Image();
        image.onload = function () {
            let vuex = state
            vuex.images.loaded_resources[item] = image
            checkLoaded()
        };
        image.onerror = () => {
            let vuex = state
            vuex.images.fail.push(image)
            vuex.loading = false
            vuex.failResources = true
        };
        image.src = item;
    })

    const checkLoaded = () => {
        let vuex = state
        let totalItems = vuex.audios.preload.length + vuex.images.preload.length
        let totalLoaded = Object.keys(vuex.audios.loaded_resources).length + Object.keys(vuex.images.loaded_resources).length
        if(totalItems == totalLoaded) {
            state.loading = false
        }
    }
}

export const playMusic = (state, payload) => {
    try {
        
        let audio = state.audios.loaded_resources[payload.name]
    
        if(payload.params) {
            for (const key in payload.params) {
                audio[key] = payload.params[key]
            }
        }

        const checkAudio = () => {
            audio.play()
            if(audio.paused) {
                setTimeout(checkAudio, 200)
            }
        }

        checkAudio()

    } catch (error) {
        console.log(error)
    }
}
export const stopMusic = (state, payload) => {
    try {
        let audio = state.audios.loaded_resources[payload.name]
        audio.pause()
        audio.currentTime = 0
    } catch (error) {
        console.log(error)
    }
}

export const changeReverse = (state) => {
    state.reverse = !state.reverse
}
export const showBlock = (state) => {
    state.block = true
    setTimeout(() => {
        state.block = false
    }, 2000)
}