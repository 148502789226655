import Vue from 'vue'

// export const exmple = (state, payload) => {
    
// }
export const setPlaying = (state, payload) => {
    state.playing = payload
}

export const setList = (state, payload) => {
    Vue.set(state, 'list', payload)
}

export const setGameplayed = (state, payload) => {
    Vue.set(state, 'gameplayed', payload)
}

export const setLives = (state, payload) => {
    Vue.set(state, 'lives', payload)
}

export const setMyScores = (state, payload) => {
    Vue.set(state, 'myScores', payload)
}

export const setRandomQuestion = (state, payload) => {
    Vue.set(state, 'question', payload)
}
