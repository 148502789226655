<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
    data() {
      return {
        online: true,
        golbal_ref: Date.now()
      }
    },
    computed: {
        layout() {
            return this.$route.meta.layout ? `Layout${this.$route.meta.layout}`  : 'div'
        },
        ...mapGetters({
          'appLoading': 'app/loading',
          'appOnline': 'app/online'
        })
    },
    methods: {
      updateConnectionStatus() {
        this.appSetStatusOnline(navigator.onLine)
      },
      ...mapMutations({
        'appSetStatusOnline': 'app/setStatusOnline'
      })
    },

    mounted() {
      // Al cargar la página, verifica el estado de conexión inicial
      window.addEventListener('load', this.updateConnectionStatus);
      // Agregar eventos para detectar cambios en la conexión
      window.addEventListener('online', this.updateConnectionStatus);
      window.addEventListener('offline', this.updateConnectionStatus);
    }
   
}
</script>
<template>
  <div style="height: 100%;" >
    <component  :is="layout">
      <router-view ></router-view>
    </component>
    <LoaderComp v-if="appLoading"/>
    <div v-if="!appOnline">
      
    </div>
  </div>
 
</template>

<style>
@import './assets/styles/normalize.css';
@import './assets/styles/base.css';
@import './assets/styles/font.css';
@import './assets/styles/global.scss';

html, body {
  height: 100%;
}

.app{
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
