<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        menu: {
            type: Boolean,
            default() {
                return true
            }
        },
        bg: {
            type: String,
            default() {
                return ''
            }
        },
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
        })
    },
    methods: {
        exitModule() {
            let code = localStorage.getItem('cs_code')
            let url =  `https://zeusru.com/?code=${code}`
            localStorage.removeItem('access_token')
            localStorage.removeItem('user')
            window.location.href = url
        }
    }
}
</script>
<template>
    <header class="header" :class="bg">
        <div>
           
        </div>
        <div class="header__logo px-2">
            <img src="/images/logo_ge.png" alt="">
        </div>

        <nav class="flex justify-center py-0 px-1 ml-auto" v-if="menu">
            <router-link  :to="{name: 'Home'}" class="btn btn-green2 btn-fullround mx-1"><span class="icon-home"></span></router-link>
            <!-- <button class="btn-blue btn-fullround mx-1"><span class="icon-settings"></span></button> -->
            <router-link v-if="user" :to="{name: 'Profile'}" class="btn  btn-blue btn-fullround mx-1"><span class="icon-user"></span></router-link>
            <router-link :to="{name: 'Ranking'}" class="btn  btn-orange btn-fullround mx-1"><span class="icon-cup"></span></router-link>
            <button  @click="exitModule()" class="btn btn-primary btn-fullround mx-1"><span class="icon-exit"></span></button>
        </nav>
    </header>
</template>
<style lang="scss" scoped>
    .icon-left{
        margin-left: -3px;
    }
   .header{
        display: flex;
        padding: 1rem 0.25rem;
        align-items: center;
        &__logo{
            min-width: 35%;
            width: 35%;
            img{
                width: 100%;
            }
        }
   }
   .btn-green2{
       &.router-link-exact-active{
        background-color: #f2f2f2;
        color: #006341;
        box-shadow: 1px 1px 0 1px #dddddd;
       }

   }
   .btn-blue{
       &.router-link-exact-active{
        background-color: #f2f2f2;
        color: #3d5693;
        box-shadow: 1px 1px 0 1px #dddddd;
       }

   }
   .btn-orange{
       &.router-link-exact-active{
        background-color: #f2f2f2;
        color: #ff6f00;
        box-shadow: 1px 1px 0 1px #dddddd;
       }

   }
</style>