import Vue from 'vue'
import Vuex from 'vuex'
import games from './games'
import ranking from './ranking'
import app from './app'
import auth from './auth'
import uno from './uno'
import rulette from './rulette'

Vue.use(Vuex)
export default new Vuex.Store({
    modules: {
        auth,
        app,
        games,
        ranking,
        uno,
        rulette
    },
})
