import service_auth from "@/services/auth";

export const login = async ({ commit }, payload) => {
  try {
    let { data } = await service_auth.login(payload);
    if (data.access_token) {
      commit("setUser", data.user);
      commit("setAccessToken", data.access_token);
      return Promise.resolve(data);
    } else {
      return Promise.reject({ data: { error: "Ups algo salio mal" } });
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

export const loginWithToken = async ({ commit }, payload) => {
  try {
    let { data } = await service_auth.loginWithToken(payload);
    if (data.access_token) {
      // let _temp = data.user;
      commit("setUser", data.user);
      commit("setAccessToken", data.access_token);
      return Promise.resolve(true);
    } else {
      return Promise.reject({ data: { error: "Ups algo salio mal" } });
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchUser = async ({ commit }) => {
  try {
    let { data } = await service_auth.user();

    if (data) {
      commit("setUser", data);
      // commit('setAccessToken', data.access_token)
      return Promise.resolve(data);
    } else {
      return Promise.reject({ data: { error: "Ups algo salio mal" } });
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

export const logout = async ({ commit }) => {
  commit("setUser", null);
  commit("setAccessToken", null);
};
