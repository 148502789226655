<script>
import HeaderComp from '@/components/LayoutDefault/HeaderComp.vue'
import FooterComp from '@/components/LayoutDefault/FooterComp.vue'

export default {
    components: {
        HeaderComp,
        FooterComp
    },
}
</script>
<template>
    <div class="layout  max-w-lg mx-auto bg-pttr-white">
        <HeaderComp :menu="false" bg=""/>
        <router-view class="layout-main"></router-view>
        <FooterComp bg=""/>
    </div>
</template>