<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        menu: {
            type: Boolean,
            default() {
                return true
            }
        },
        logo: {
            type: Boolean,
            default() {
                return true
            }
        },
        bg: {
            type: String,
            default() {
                return 'bg-pttr-white'
            }
        },
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
        })
    },
}
</script>
<template>
    <header v-if="logo" class="header" :class="bg">
        <div class="flex justify-center py-3 px-1" v-if="menu">
            <button class="btn-primary btn-fullround mx-1"><span class="icon-settings"></span></button>
        </div>
        <div>
            <img src="/images/headerv2.png" alt="">
        </div>
        <nav class="flex justify-center py-3 px-1" v-if="menu">
            <router-link :to="{name: 'Home'}" class="btn btn-primary btn-fullround mx-1"><span class="icon-left"></span></router-link>
            <router-link v-if="user" :to="{name: 'Profile'}" class="btn btn-primary btn-fullround mx-1"><span class="icon-user"></span></router-link>
            <router-link :to="{name: 'Ranking'}" class="btn btn-primary btn-fullround mx-1"><span class="icon-cup"></span></router-link>
        </nav>
    </header>
    <header v-else class="header" :class="bg">
        <div>
            <img src="/images/header_2.png" alt="">
        </div>
    </header>
</template>
<style lang="scss" scoped>
   .header{
        position: relative;
        &__logo{
            height: 50px;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                height: 30px;
            }
        }
   }
  
</style>