export default {
    socket: null,
    timeByUser: 10,
    avatars: [
        {
            id: 1,
            path: '/rulette_game/avatars/1.png',
        },
        {
            id: 2,
            path: '/rulette_game/avatars/2.png',
        },
        {
            id: 3,
            path: '/rulette_game/avatars/3.png',
        },
        {
            id: 4,
            path: '/rulette_game/avatars/4.png',
        },
    ],
    avatar_selected: null,
    categories: null,
    questionBank: null,
    currentUserId: null,
    currentTimer: 0,
    status: 'SELECTED_AVATAR',
    users: [],
    answers: '',
}