import Vue from 'vue'
import axios  from 'axios'
import App from './App.vue'
import LoaderComp from './components/LoaderComp.vue'

import LayoutDefault from './layouts/LayoutDefault.vue'
import LayoutClean from './layouts/LayoutClean.vue'
import LayoutGame from './layouts/LayoutGame.vue'
import LayoutGameMultiplayer from './layouts/LayoutGameMultiplayer.vue'
import LayoutMain from './layouts/LayoutMain.vue'

import router from './router'
import store from './store'
import './registerServiceWorker'

// axios.defaults.headers.common['AppKey'] = 'SA&A-b$02fy8$41207$5tfH3r2i0T3Vc73p7qfW577x2I411E7c9y1K405K5wce6'
axios.interceptors.request.use(function (config) {
  config.headers.AppKey = 'SA&A-a$e25y6$e100a$8x2Ra85EdL744qaidu3s6q0ldF5R818q8Ncd6ocvb2d.9';
  try {
      let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : null
      if(token) {
          config.headers.Authorization = `Bearer ${token}`
      }
  } catch (error) {
      console.log(error)
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if(error.response.status == 401) {
      localStorage.clear()
      sessionStorage.clear()
      // location.reload()
  }
  // if(error.response.status == 400) {
  //     localStorage.clear()
  //     sessionStorage.clear()
  //     // location.reload()
  // }
});

Vue.component('LoaderComp', LoaderComp)
Vue.component('LayoutDefault', LayoutDefault)
Vue.component('LayoutClean', LayoutClean)
Vue.component('LayoutGame', LayoutGame)
Vue.component('LayoutMain', LayoutMain)
Vue.component('LayoutGameMultiplayer', LayoutGameMultiplayer)

Vue.config.productionTip = false
Vue.mixin({
  data () {
    return {
      publicPath: process.env.BASE_URL,
      soundOn: true,
      assetsObj: {
        audio: {}
      },
      assetsErrorQueue: []
    }
  },
  filters: {
    capitalising: function (data) {
      var capitalized = []
      data.split(' ').forEach(word => {
        capitalized.push(
          word.charAt(0).toUpperCase() +
          word.slice(1).toLowerCase()
        )
      })
      return capitalized.join(' ')
    }
  },
  methods: {
    formPublic(path) {
      return `${this.publicPath}${path}`
    },
    addAudio(name, src, retry = 0) {
      if (!this.soundOn) return
      const a = new window.Audio()
      a.src = src
      // a.addEventListener('canplaythrough', () => {
      //   this.assetsObj.audio[name] = a
      // }, false)
      // bug sometime not trigger canplaythrough
      this.assetsObj.audio[name] = a
      a.addEventListener('error', () => {
        this.assetsErrorQueue.push({
          name,
          src,
          retry: retry + 1,
          type: 'audio'
        })
      }, false)
      a.load()
    },
    getAudio(name) {
      return this.assetsObj.audio[name]
    },
  
    playAudio(name, loop = false) {
      if (!this.soundOn) return

      const audio = this.getAudio(name)
      // const audio = document.getElementById(name)
      if (audio) {
        audio.play()
        
        if (!loop) return
        audio.addEventListener('ended', () => {
          audio.currentTime = 0
          audio.play()
        }, false)
      }
    },
  
    pauseAudio(name) {
      const audio = this.getAudio(name)
      if (audio) {
        audio.pause()
      }
    },

    go(name) {
      this.$router.push({name: name})
    }
  }
})
window.Event = new Vue();
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

// En el archivo main.js


