let principles = [1,2,3,4,5,6,7]

let colors = [1,2,3,4]
let index_color = 0
let cards = principles.map(p => {
    if(index_color == 4) {
        index_color = 0
    }
    return {
        id: p,
        card: `/uno_game/cards/${p}/0.png`,
        color: colors[index_color++],
        principle: p,
        add: 0,
        type: 'PRINCIPLE'
    }
})
let axisCards = []
index_color = 1
cards.forEach(p => {
    let axis = [1,2,3]
    index_color = p.color
    axis.forEach(a => {
        if(index_color == 4) {
            index_color = 0
        }
        axisCards.push({
            id: cards.length + axisCards.length + 1,
            card: `/uno_game/cards/${p.principle}/${a}.png`,
            color: colors[index_color++],
            principle: p.principle,
            add: 0,
            type: 'AXI'
        })
    })
})
let othersCards = [{
    id: cards.length + axisCards.length  + 1,
    card: `/uno_game/cards/8.png`,
    color: 0,
    principle: 'CHANGE_COLOR',
    add: 0,
    type: 'CHANGE_COLOR'
}, {
    id: cards.length + axisCards.length  + 2,
    card: `/uno_game/cards/9.png`,
    color: 0,
    principle: 'CHANGE_COLOR',
    add: 0,
    type: 'CHANGE_COLOR'
}, {
    id: cards.length + axisCards.length  + 3,
    card: `/uno_game/cards/10.png`,
    color: 0,
    principle: 'ADD_4',
    add: 4,
    type: 'ADD_4'
}, {
    id: cards.length + axisCards.length  + 4,
    card: `/uno_game/cards/11.png`,
    color: 1,
    principle: 'BLOCK',
    add: 0,
    type: 'BLOCK'
}, {
    id: cards.length + axisCards.length  + 5,
    card: `/uno_game/cards/12.png`,
    color: 2,
    principle: 'BLOCK',
    add: 0,
    type: 'BLOCK'
}, {
    id: cards.length + axisCards.length  + 6,
    card: `/uno_game/cards/13.png`,
    color: 3,
    principle: 'BLOCK',
    add: 0,
    type: 'BLOCK'
}, {
    id: cards.length + axisCards.length  + 7,
    card: `/uno_game/cards/14.png`,
    color: 4,
    principle: 'BLOCK',
    add: 0,
    type: 'BLOCK'
}, {
    id: cards.length + axisCards.length  + 8,
    card: `/uno_game/cards/15.png`,
    color: 1,
    principle: 'REVERSE',
    add: 0,
    type: 'REVERSE'
}, {
    id: cards.length + axisCards.length  + 9,
    card: `/uno_game/cards/16.png`,
    color: 2,
    principle: 'REVERSE',
    add: 0,
    type: 'REVERSE'
}, {
    id: cards.length + axisCards.length  + 10,
    card: `/uno_game/cards/17.png`,
    color: 3,
    principle: 'REVERSE',
    add: 0,
    type: 'REVERSE'
}, {
    id: cards.length + axisCards.length  + 11,
    card: `/uno_game/cards/18.png`,
    color: 4,
    principle: 'REVERSE',
    add: 0,
    type: 'REVERSE'
}, {
    id: cards.length + axisCards.length  + 12,
    card: `/uno_game/cards/19.png`,
    color: 1,
    principle: 'ADD_2',
    add: 2,
    type: 'ADD_2'
}, {
    id: cards.length + axisCards.length  + 13,
    card: `/uno_game/cards/20.png`,
    color: 4,
    principle: 'ADD_2',
    add: 2,
    type: 'ADD_2'
}, {
    id: cards.length + axisCards.length  + 14,
    card: `/uno_game/cards/21.png`,
    color: 3,
    principle: 'ADD_2',
    add: 2,
    type: 'ADD_2'
}, {
    id: cards.length + axisCards.length  + 15,
    card: `/uno_game/cards/22.png`,
    color: 2,
    add: 2,
    principle: 'ADD_2',
    type: 'ADD_2'
},{
    id: cards.length + axisCards.length  + 16,
    card: `/uno_game/cards/8.png`,
    color: 0,
    principle: 'CHANGE_COLOR',
    add: 0,
    type: 'CHANGE_COLOR'
}, {
    id: cards.length + axisCards.length  + 17,
    card: `/uno_game/cards/9.png`,
    color: 0,
    principle: 'CHANGE_COLOR',
    add: 0,
    type: 'CHANGE_COLOR'
}, {
    id: cards.length + axisCards.length  + 18,
    card: `/uno_game/cards/10.png`,
    color: 0,
    principle: 'ADD_4',
    add: 4,
    type: 'ADD_4'
}
]

let allCards = [...cards, ...axisCards, ...othersCards]
console.log(allCards)
export default {
    loading: true,
    failResources: false,
    socket: null,
    timeByUser: 15,
    colors: [
        {
            id: 2,
            value: '#ffd100',
            label: 'AMARILLO'
        },
        {
            id: 3,
            value: '#4bab34',
            label: 'VERDE'
        },
        {
            id: 4,
            value: '#ea660a',
            label: 'NARANJA'
        },
        {
            id: 1,
            value: '#e1261d',
            label: 'ROJO'
        }
    ],
    currentColor: null,
    avatars: [
        {
            id: 1,
            path: '/uno_game/avatars/1.png',
        },
        {
            id: 2,
            path: '/uno_game/avatars/2.png',
        },
        {
            id: 3,
            path: '/uno_game/avatars/3.png',
        },
        {
            id: 4,
            path: '/uno_game/avatars/4.png',
        },
    ],
    avatar_selected: null,
    status: 'SELECTED_AVATAR',
    users: [],
    cards: allCards,
    inTableCards: [],
    deckCards: [],
    myHand: [],
    currentUserId: null,
    currentTimer: 0,
    dropingCard: false,
    takingCard: false,
    changeColor: {
        card: null,
        show:  false,
        color: 0
    },
    sayOne: {
        show: false,
        trigger: false,
        timer: null,
        timeout: false
    },
    winner: null,
    gameTimer: null,

    audios: {
        preload: [],
        loaded_resources: {},
        fail: []
    },

    images: {
        preload: [],
        loaded_resources: {},
        fail: []
    },
    listMessages: [],
    reverse: false,
    block: false
}