import Vue from "vue";
import VueRouter from "vue-router";
// import store from "../store";
import middlewares from '@/middlewares/index'
Vue.use(VueRouter);

// store.commit("loginStore/SET_ACCESS_TOKEN", "restore");
// store.commit("auth/SET_PROFILE", "restore");

//ROUTES
const routerOptions = [
	{
		path: "/",
		name: "Home",
        meta: {
            layout: "Main",
			middleware: [middlewares.auth]
        }
	},
	{
		path: "/ranking",
		name: "Ranking",
        meta: {
            layout: "Main",
			middleware: [middlewares.auth]
        }
	},
	{
		path: "/perfil",
		name: "Profile",
        meta: {
            layout: "Main",
			middleware: [middlewares.auth]
        }
	},
	{
		path: "/juego/:id",
		name: "Game",
        meta: {
            layout: "Game",
			middleware: [middlewares.auth]
        }
	},
	{
		path: "/inicio",
		name: "Login",
        meta: {
            layout: "Clean",
			middleware: [middlewares.guest]
        }
	},
	{
		path: "/uno-game",
		name: "UnoGame",
        meta: {
            layout: "GameMultiplayer",
			middleware: [middlewares.auth]
        }
	},
	{
		path: "/rulette-game",
		name: "RuletteGame",
        meta: {
            layout: "GameMultiplayer",
			middleware: [middlewares.auth]
        }
	},
];

//LAZY_LOAD
const routes = routerOptions.map((r) => {
	if (!r.children) {
		return {
			...r,
			component: () => import(`@/views/${r.name}/IndexComp.vue`),
		};
	} else {
		const children = r.children.map((c) => {
			let childName = c.name;
			c.component = () => import(`@/views/${r.name}/${childName}.vue`);
			return { ...c };
		});

		return {
			...r,
			component: () => import(`@/views/${r.name}/Index.vue`),
			children: [...children],
		};
	}
});

const router = new VueRouter({
	routes,
});

router.beforeEach((to, from, next) => {
	document.querySelector('html').scrollTop = 0
    if(to.name != from.name) {
        // sto're.commit('status/showLoadingScreen')
    }

    if (to.meta.middleware) {
        const middlewareArr = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware]

        const context = {
            from,
            next,
            router,
            to,
        };

        const nextMiddleware = nextFactory(context, middlewareArr, 1)
        return middlewareArr[0]({ ...context, next: nextMiddleware })
    }

    return next();

});



function nextFactory (context, middlewareArr, index) {
    const subsequentMiddleware = middlewareArr[index]

    if (!subsequentMiddleware) {
      return context.next
    }

    return (param) => {
        if (param !== undefined) {
            return context.next(param)
        }
        const nextMiddleware = nextFactory(context, middlewareArr, index + 1)
        subsequentMiddleware({ ...context, next: nextMiddleware })
    }
}

export default router;
