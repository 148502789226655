export default {
    lives: null,
    list: [
        {
            id: 1,
            key: 'block_tower',
            number: 1,
            score: 2156,
            max_score: 100,
            url: '/games/tower/',
            name: 'Torre de Cultura',
            description: 'Alinea todos los pisos y gana puntos si alineas pefecto recibes bono'
        },
        {
            id: 2,
            number: 2,
            score: 2156,
            max_score: 100,
            url: 'http://192.168.0.103:8082/',
            name: 'Corre ru',
            description: 'Alinea todos los pisos y gana puntos si alineas pefecto recibes bono'
        },
        {
            id: 3,
            number: 3,
            score: 2156,
            max_score: 100,
            url: 'http://192.168.0.103:8082/',
            name: 'Uno',
            description: 'Alinea todos los pisos y gana puntos si alineas pefecto recibes bono'
        },
        {
            id: 4,
            number: 4,
            score: 2156,
            max_score: 100,
            url: 'http://192.168.0.103:8082/',
            name: 'Ruleta',
            description: 'Alinea todos los pisos y gana puntos si alineas pefecto recibes bono'
        },
    ],
    playing: null,
    myScores: [],
    gameplayed: null,
    question: null,
}