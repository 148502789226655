export const list = (state) => {
    return state.list
}
export const totalScore = (state) => {
    let sum = 0
    state.list.forEach(element => {
        sum += element.score
    });
    return sum
}
export const playing = (state) => {
    return state.myScores.find(g => g.key == state.playing)
}
export const myScores = (state) => {
    return state.myScores
}

export const gameplayed = (state) => {
    return state.gameplayed
}
export const lives = (state) => {
    return state.lives
}
export const question = (state) => {
    return state.question
}

