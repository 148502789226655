import service_games from '@/services/games'
// export const example = async ({commit}, payload) => {
   
// }

export const getList = async ({commit}) => {
    try {
        let {data} = await service_games.list()
        console.log(JSON.parse(JSON.stringify(data)))
        commit('setList', data)
        return Promise.resolve(data)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const myScores = async ({commit}) => {
    try {
        let {data} = await service_games.myScores()
        commit('setMyScores', data)
        return Promise.resolve(data)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const checkLives = async ({commit}) => {
    try {
        let data = await service_games.checkLives()
        console.log(data)
        commit('setLives', data.data)
        return Promise.resolve(data)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const newGameplayed = async ({commit}, payload) => {
    try {
        let {data} = await service_games.newGameplayed(payload)
        console.log('GEMPLAYED ACTIOB', data)
        commit('setGameplayed', data)
        return Promise.resolve(data)
    } catch (error) {
        return Promise.reject(error)
    }
}
export const setPointsGameplayed = async ({commit}, payload) => {
    try {
        let {data} = await service_games.setPointsGameplayed(payload)
        commit('setGameplayed', data)
        return Promise.resolve(data)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getRandomQuestion = async ({commit}) => {
    try {
        let {data} = await service_games.getRandomQuestion()
        commit('setRandomQuestion', data)
        return Promise.resolve(data)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const newGameplayedByQuestion = async ({commit}, payload) => {
    try {
        let {data} = await service_games.newGamePlayedByQuestion(payload)
        console.log('GEMPLAYED ACTIOB', data)
        commit('setGameplayed', data)
        return Promise.resolve(data)
    } catch (error) {
        return Promise.reject(error)
    }
}