<script>
import HeaderComp from '@/components/LayoutDefault/HeaderLogo.vue'
// import FooterComp from '@/components/LayoutDefault/FooterComp.vue'

export default {
    components: {
        HeaderComp,
        // FooterComp
    },
}
</script>
<template>
    <div class="layout  max-w-lg mx-auto">
        <HeaderComp/>
        <router-view class="layout-main"></router-view>
        <!-- <FooterComp/> -->
    </div>
</template>