<script>
import HeaderComp from '@/components/LayoutDefault/HeaderComp.vue'
import FooterComp from '@/components/LayoutDefault/FooterComp.vue'

export default {
    components: {
        HeaderComp,
        FooterComp
    },
    data() {
        return {
            count_mosaics: 1
        }
    },
    
    methods: {
        mixMosaic() {
            let mosaics = document.querySelectorAll('.loader-mosaic>.img')
            mosaics.forEach(m => {
                m.querySelector('img').src = this.getRandomMosaic()
                this.setRamdon(m)
                m.addEventListener('animationiteration', this.setRamdonMosaic)
            })
        },
        setRamdon(m) {
            m.style.animationPlayState = 'initial';
            m.style.animationName = "none"
            setTimeout(() => {
                m.style.animationDelay = `${Math.random()}s`
                let duration = Math.floor(Math.random() * (3 - 1 + 1) + 2)
                m.style.animationDuration = `${duration || 1 }s`
                m.style.animationPlayState = 'running';
                m.style.animationName = "mosaic"
            }, 1)
        },
        setRamdonMosaic(m) {
            m.target.querySelector('img').src = this.getRandomMosaic()
            this.setRamdon(m.target)
        },
        getRandomMosaic() {
            let index = Math.floor(Math.random() * (49 - 0 + 1) + 0)
            return `/images/pttr/pttr_${index}.png`
        },
        setMosaics() {
            let m = document.querySelector('.loader-mosaic')
            let m_width = m.clientWidth

             console.log(m_width, window.innerHeight, Math.ceil(window.innerHeight / m_width), Math.round(window.innerWidth / m_width))
            this.count_mosaics = Math.ceil(window.innerHeight / m_width) * Math.round(window.innerWidth / m_width)
            console.log(this.count_mosaics)
            this.$nextTick(() => {
                this.mixMosaic()
            })

        }
    },
    mounted() {
        // this.setMosaics()
        // window.addEventListener('resize', this.setMosaics);
    },
    destroyed() {
        // window.removeEventListener('resize', this.setMosaics);
    }
}
</script>
<template>
    <div class="loader-wrap flex flex-wrap bg-pttr-white" >
        <div class="max-w-lg mx-auto flex flex-wrap loader-content">
            <HeaderComp :menu="false" bg=""/>
            
            <img class="loader-logo-ge " src="/images/logo_ge.png" alt="">
            <div class="loader-logo-wrap">
                <div class="loader-logo">
                    <img class="loader-logo-caravana" src="/images/logo.png" alt="">
                </div>
            </div>
            <FooterComp/>
        </div>
        <!-- <div class="loader-mosaic" v-for="(n, index) in count_mosaics" :key="`mosaic-${n}`">
            <div class="img">
                <img :src="`/images/pttr/pttr_${index}.png`" alt="" v-if="index <= 49">
                <img :src="`/images/pttr/pttr_${index - (index % 100 == 0 ? index / 100  + 50 : 50)}.png`" alt="" v-else>
            </div>
        </div> -->
    </div>
</template>
<style lang="scss">
.loader-logo-wrap{
    height: 50%;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
    .loader-content{
        flex-direction: column;
        justify-content: space-between;
        align-content: center;
        height: 100%;
    }
    .loader-wrap{
        // background: #fff;
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        align-content: center;
    }
    .loader-logo{
        // position: absolute;
        z-index: 2;
        // left: 50%;
        // top: 50%;
        width: 50%;
        // transform: translateX(-50%) translateY(-50%);
        max-width: 300px;
        min-width: 200px;
        background: #fff;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        .loader-logo-caravana{
            animation: pulse infinite alternate ease-in-out 4s;
            margin-top: -2rem; 
        }
       
    }
    .loader-logo-ge{
        width: 160px;
        margin: 0 auto;
        margin-bottom: auto;
        margin-top: 2rem;
    }
    .loader-mosaic{
        width: 20%;
        position: relative;
        padding: 1px;

        .img{
            opacity: 0;
            transform: scale(0);
            display: block;
            width: 100%;
            animation: mosaic infinite;
            background: red;
            img{
                width: 100%;
            }
        }
        @media (min-width: 300px) {
            width: 16.66%;
        }
        @media (min-width: 780px) {
            width: 10%;
        }
        @media (min-width: 980px) {
            width: 8.33%;
        }
        @media (min-width: 1900px) {
            width: 5%;
        }
    }

    $percent: 1%;
    @for $i from 1 through 100 {
    .loader-mosaic:nth-of-type(#{$i}) {
        img{

            transition-delay: random() + s;
            transition-delay: random() + s;
            animation-delay: random() + s;
            animation-duration: random(3)  + s;
            animation-timing-function: ease-in-out;
            overflow: hidden;
        }
    }
    }
    @keyframes mosaic {
        0%{
            opacity: 0.5;
            transform: scale(0);
            border-radius: 100%;
            overflow: hidden;
        } 40% {
            transform: scale(1);
            opacity: 1;
            border-radius: 0;
            overflow: hidden;
        } 60% {
            transform: scale(1);
            opacity: 1;
            border-radius: 0;
            overflow: hidden;
        } 
        100%{
            transform: scale(0);
            opacity: 0.5;
            border-radius: 100%;
            overflow: hidden;
        }
    }

    @keyframes pulse {
        0%{
            opacity: 0.7;
            transform: scale(0.9);
            border-radius: 100%;
            overflow: hidden;
        } 40% {
            transform: scale(1);
            opacity: 1;
            border-radius: 0;
            overflow: hidden;
        } 60% {
            transform: scale(1);
            opacity: 1;
            border-radius: 0;
            overflow: hidden;
        } 
        100%{
            transform: scale(0.9);
            opacity: 0.7;
            border-radius: 100%;
            overflow: hidden;
        }
    }
    
</style>