export const avatars = (state) => {
    return state.avatars
}
export const meAvatar = (state) => {
    return state.avatars.find(item => item.id == state.avatar_selected)
}
export const status = (state) => {
    return state.status
}

export const users = (state) => {
    return state.users
}

export const cards = (state) => {
    return state.cards
}
export const inTableCards = (state) => {
    return state.inTableCards
}
export const lastInTableCard = (state) => {
    return state.inTableCards[state.inTableCards.length - 1]
}

export const myHand = (state) => {
    return state.myHand
}
export const deckCards = (state) => {
    return state.deckCards
}
export const currentUserId = (state) => {
    return state.currentUserId
}
export const currentTimer = (state) => {
    return state.currentTimer
}
export const timeByUser = (state) => {
    return state.timeByUser
}
export const dropingCard = (state) => {
    return state.dropingCard
}
export const takingCard = (state) => {
    return state.takingCard
}
export const socket = (state) => {
    return state.socket
}
export const changeColor = (state) => {
    return state.changeColor
}
export const currentColor = (state) => {
    return state.currentColor
}
export const sayOne = (state) => {
    return state.sayOne
}
export const winner = (state) => {
    return state.winner
}
export const loading = (state) => {
    return state.loading
}

export const listMessages = (state) => state.listMessages
export const block = (state) => state.block
export const reverse = (state) => state.reverse
export const currentMessage = (state) => state.listMessages.length ? state.listMessages[0] : null


