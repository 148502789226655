export const list = (state) => {
    return state.list
}
export const ranking = (state) => {
    return state.ranking
}
export const totalScore = (state) => {
    let sum = 0
    state.list.forEach(element => {
        sum += element.score
    });
    return sum
}


