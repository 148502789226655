import axios from "axios";

// const BASE = "https://admin-caravana.saa-redunica.com/api";
const BASE = "https://caravana.sagacampus.com/api";

export default {
  async login(payload) {
    let endpoint = `${BASE}/auth/login`;
    return await axios.post(endpoint, payload);
  },
  async loginWithToken(payload) {
    let endpoint = `${BASE}/auth/loginWithToken`;
    return await axios.post(endpoint, payload);
  },
  async user() {
    let endpoint = `${BASE}/user`;
    return await axios.get(endpoint);
  },
};
