// import moment from "moment"
import store from "@/store";

export default async ({ to, router, next }) => {
  const vuex = store;

  const failAuth = () => {
    vuex.dispatch("auth/logout");
    if (to.query && Object.keys(to.query).length > 0) {
      // Si envian datos de inicio de session, los pasamos al loguin
      router.push({ name: "Login", query: to.query });
    } else {
      return router.push({ name: "Login" });
    }
  };

  let token = localStorage.getItem("access_token") || null;

  if (!token) {
    return failAuth();
  }

  try {
    let user = JSON.parse(localStorage.getItem("user")) || null;

    if (to.query && Object.keys(to.query).length > 0 && to.query["code"]) {
      vuex.commit("auth/setUser", null);
      vuex.commit("auth/setAccessToken", null);
      router.push({ name: "Login", query: to.query });
    } else {
      vuex.commit("auth/setUser", user);
      vuex.commit("auth/setAccessToken", token);
      return next();
    }
  } catch (error) {
    vuex.dispatch("auth/logout");
  }
};
