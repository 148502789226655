import store from "@/store"

export default ({router, next}) => {
	
    const token = localStorage.getItem('access_token') || null
    if (token) {
        console.log('GUEST')
        store.commit('app/showLoading', false)
        return router.push({ name: 'Home' }).catch(() => {})
    }
    return next();
}