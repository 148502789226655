import axios from 'axios'

// const BASE = 'https://admin-caravana.saa-redunica.com/api'
const BASE = 'https://caravana.sagacampus.com/api'

export default {
    async list() {
        let endpoint = `${BASE}/games`
        return await axios.get(endpoint)
    },
    async myScores() {
        let endpoint = `${BASE}/myScores`
        return await axios.get(endpoint)
    },
    async checkLives() {
        let endpoint = `${BASE}/games/check_available_lives`
        return await axios.get(endpoint)
    },

    async newGameplayed(key) {
        let endpoint = `${BASE}/games/new_game_played/${key}`
        return await axios.get(endpoint)
    },
    
    async setPointsGameplayed(payload) {
        let endpoint = `${BASE}/games/setpoints/${payload.id}`
        return await axios.post(endpoint, {
            points: payload.points,
            winner: payload.winner,
            answers: payload.answers
        })
    },

    async setAnswerGameplayed(payload) {
        let endpoint = `${BASE}/games/set_answers/${payload.id}`
        return await axios.post(endpoint, {
            answers: payload.answers
        })
    },

    async getRanking(payload) {
        let endpoint = `${BASE}/games/top`

        return await axios.get(endpoint, {
            params: payload
        })
    },

    async getRandomQuestion() {
        let endpoint = `${BASE}/games/ramdom_question`

        return await axios.get(endpoint)
    },
  
    async newGamePlayedByQuestion(payload) {
        let endpoint = `${BASE}/games/new_game_played_by_question/${payload.game_key}`

        return await axios.post(endpoint, {id: payload.id, answer:  payload.answer})
    },

    async getRouletteQuestionCategory(){
        let endpoint = `${BASE}/quizzes/questionnaire/roulette_questions/question_category`

        return await axios.get(endpoint);
    },
    async getQuestionBank(){
        let endpoint = `${BASE}/quizzes/questionnaire/general_knowledge_bank`;
        return await axios.get(endpoint);
    }

}