import service_games from '@/services/games'
// export const example = async ({commit}, payload) => {
   
// }

export const getRanking = async ({commit}, payload) => {
   try {
        let {data} = await service_games.getRanking(payload)
        console.log('RESULT', data)
        commit('setRanking', data)
        return Promise.resolve(data)
   } catch (error) {
        return Promise.reject(error)
   }
}