export default {
    ranking: {
        current_page: null,
        data: [],
        last_page: null
    },
    list: [
        {
            id: 1,
            score: 2156,
            name: 'Nombre del colaborador',
            agency: 'Punto de contacto',
            avatar: '/images/profile-1.png',
            color: '#A8D6CB'
        },
        {
            id: 2,
            score: 2156,
            name: 'Nombre del colaborador',
            agency: 'Punto de contacto',
            avatar: '/images/profile-1.png',
            color: '#F4B284'
        },
        {
            id: 3,
            score: 2156,
            name: 'Nombre del colaborador',
            agency: 'Punto de contacto',
            avatar: '/images/profile-1.png',
            color: '#7FB1A0'
        },
        {
            id: 4,
            score: 2156,
            name: 'Nombre del colaborador',
            agency: 'Punto de contacto',
            avatar: '/images/profile-1.png',
            color: '#fae37d'
        },
        {
            id: 5,
            score: 2156,
            name: 'Nombre del colaborador',
            agency: 'Punto de contacto',
            avatar: '/images/profile-1.png',
            color: '#D79397'
        },
        {
            id: 5,
            score: 2156,
            name: 'Nombre del colaborador',
            agency: 'Punto de contacto',
            avatar: '/images/profile-1.png',
            color: '#D79397'
        },
        {
            id: 5,
            score: 2156,
            name: 'Nombre del colaborador',
            agency: 'Punto de contacto',
            avatar: '/images/profile-1.png',
            color: '#D79397'
        },
        {
            id: 5,
            score: 2156,
            name: 'Nombre del colaborador',
            agency: 'Punto de contacto',
            avatar: '/images/profile-1.png',
            color: '#D79397'
        },
        {
            id: 5,
            score: 2156,
            name: 'Nombre del colaborador',
            agency: 'Punto de contacto',
            avatar: '/images/profile-1.png',
            color: '#D79397'
        },
        {
            id: 5,
            score: 2156,
            name: 'Nombre del colaborador',
            agency: 'Punto de contacto',
            avatar: '/images/profile-1.png',
            color: '#D79397'
        },
        {
            id: 5,
            score: 2156,
            name: 'Nombre del colaborador',
            agency: 'Punto de contacto',
            avatar: '/images/profile-1.png',
            color: '#D79397'
        },
    ],
}